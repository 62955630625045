//new
// import '../styles/twPreflight.css'; // Extension only (for now)
import '../styles/tailwindOnly.css';
import '../styles/editorStyles.css';
import '../styles/CustomEditorStyles.css';
import '../styles/CustomStyles.css';
import '../styles/Toastify.css';
import '../styles/animate.css';
// import '../styles/reset.css';

// Editor Plugin Styles
import '../styles/editorPluginStyles/PlaygroundEditorTheme.css';

import '../styles/editorPluginStyles/CommentEditorTheme.css';
import '../styles/editorPluginStyles/ImageNode.css';
import '../styles/editorPluginStyles/PollNode.css';
import '../styles/editorPluginStyles/Collapsible.css';
import '../styles/editorPluginStyles/Button.css';
import '../styles/editorPluginStyles/ColorPicker.css';
import '../styles/editorPluginStyles/ContentEditable.css';
import '../styles/editorPluginStyles/Dialog.css';
import '../styles/editorPluginStyles/ErrorBoundary.css';
import '../styles/editorPluginStyles/Input.css';
import '../styles/editorPluginStyles/KatexEquationAlterer.css';
import '../styles/editorPluginStyles/LinkPreview.css';
import '../styles/editorPluginStyles/Modal.css';
import '../styles/editorPluginStyles/Placeholder.css';
import '../styles/editorPluginStyles/CodeActionMenuPlugin.css';
import '../styles/editorPluginStyles/CodeActionMenuContainer.css';
import '../styles/editorPluginStyles/CommentPlugin.css';
import '../styles/editorPluginStyles/DraggableBlock.css';
import '../styles/editorPluginStyles/LinkEditor.css';
import '../styles/editorPluginStyles/FloatingTextFormat.css';
import '../styles/editorPluginStyles/TableCellResizer.css';
import '../styles/editorPluginStyles/TableOfContents.css';

import 'react-tooltip/dist/react-tooltip.css'

import { MessageContextProvider } from '../components/hooks/AlertContext';
// import * as Sentry from '@sentry/react';
import ErrorPage from '../components/common/ErrorPage';
import ErrorBoundary from '../components/common/ErrorBoundary';
import { ToastContainer, toast, Zoom } from 'react-toastify';
export { reportWebVitals } from 'next-axiom';

function MyApp({ Component, pageProps }) {
  return (
    <div id="startscreen-app-root">
      <MessageContextProvider>
        <ToastContainer
          position={'top-right'}
          autoClose={5000}
          hideProgressBar={true}
          closeOnClick
          newestOnTop={true}
          enableMultiContainer
          containerId={'toast'}
        />

        {/* <ErrorBoundary fallback={<ErrorPage />}> */}
        <Component {...pageProps} />
        <div id="headlessui-portal-root">
          {/* It needs at least one child, so that HeadlessUI doesn't remove this portal root workaround
        ( https://github.com/tailwindlabs/headlessui/blob/main/packages/@headlessui-react/src/components/portal/portal.tsx#L84 ) */}
          <div></div>
        </div>
        {/* </ErrorBoundary> */}
      </MessageContextProvider>
    </div>
  );
}

export default MyApp;
