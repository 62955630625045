import React, { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import {
  InformationCircleIcon,
  ExclamationIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';

export const alertContext = React.createContext();

export function useAlertContext() {
  const context = React.useContext(alertContext);
  if (context === undefined) {
    throw new Error(
      'useAlertContext must be called within a MessageContextProvider'
    );
  }
  return context;
}

const AUTO_DISMISS = 3000;

export function MessageContextProvider({ children }) {
  const [alerts, setAlerts] = React.useState([]);
  // const classes = useStyles();

  const activeAlertIds = alerts.join(',');
  React.useEffect(() => {
    if (activeAlertIds.length > 0) {
      const timer = setTimeout(
        () => setAlerts(() => alerts.slice(0, alerts.length - 1)),
        AUTO_DISMISS
      );
      return () => clearTimeout(timer);
    }
  }, [activeAlertIds]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlerts(() => alerts.slice(0, alerts.length - 1));
  };

  const ErrorMessage = ({ text, status }) => {
    let StatusIcon = () => (
      <InformationCircleIcon class="h-8 mr-2 text-green-500" />
    );
    if (status === 'info')
      StatusIcon = () => (
        <InformationCircleIcon class="h-8 w-8 mr-2 text-blue-500" />
      );
    else if (status === 'warning')
      StatusIcon = () => (
        <ExclamationTriangleIcon class="h-8 w-8 mr-2 text-orange-400" />
      );
    else if (status === 'error')
      StatusIcon = () => (
        <ExclamationTriangleIcon class="h-8 w-8 mr-2 text-red-500" />
      );

    return (
      <Transition
        show={true}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {/* Global notification live region, render this permanently at the end of the document */}
        <div
          aria-live="assertive"
          className="fixed bottom-0 right-0 left-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
        >
          <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-center justify-between">
                  <StatusIcon />
                  <p className="w-0 flex-1 text-sm font-medium text-gray-900">
                    {text}
                  </p>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={handleClose}
                    >
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    );
  };

  const showAlert = React.useCallback(
    (alert) => setAlerts(() => [alert, ...alerts]),
    []
  );

  const value = React.useMemo(() => ({ showAlert }), [showAlert]);

  return (
    <alertContext.Provider value={value}>
      {children}
      {alerts.map((alert) => (
        <ErrorMessage key={alert} text={alert.text} status={alert.status} />
      ))}
    </alertContext.Provider>
  );
}
// <ErrorMessage key={alert} text={alert.text} status={alert.status} />
